/* eslint-disable react/no-danger */
import * as Logger from '@common/Logger';
import { SmallLoader } from '@common/components/Loader';
import {
    Modal,
    ModalBackDrop,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '@common/components/Modal';
import { invertedTheme } from '@common/components/Modal.styles';
import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import InstallationsTable from './components/InstallationsTable';
import { useAppSelector } from './redux';
import actions from './redux/actions';
import selectors from './redux/selectors';
import * as Styles from './styles';

type PropsType = {
    close: () => void;
};

const App = ({ close }: PropsType): JSX.Element => {
    const dispatch = useDispatch();
    const searchInput = useAppSelector((state) => state.appState.searchInput);
    const [input, setInput] = useState<string>(searchInput || '');
    const isLoading = useAppSelector((state) => state.appState.isLoading);
    const isSaving = useAppSelector((state) => state.appState.isSaving);
    const installations = useAppSelector((state) => state.installationsData.installations);
    const filteredInstallations = useAppSelector(selectors.installations.getFilteredInstallations);
    const selected = useAppSelector((state) => state.installationsData.selected);
    const seatsLeft = useAppSelector((store) => store.settings.seatsLeft);
    const upgradeToNextRange = useAppSelector((store) => store.settings.urls.upgradeToNextRange);

    const totalNrOfInstallationsLabel = i18n('total_number_of_installations').replace(
        '%totalNumberOfInstallations%',
        installations ? `${filteredInstallations.length}` : '0',
    );

    const footerLabelBtn = i18n('add_installations_button').replace(
        '%numberOfInstallations%',
        `${selected.length === 0 ? '' : selected.length} `,
    );

    const showAlert = selected.length > seatsLeft && !!upgradeToNextRange;
    const alertMessage = i18n('max_installations_allowed')
        .replace('%numberOfSeatsLeft%', `${seatsLeft}`)
        .replace('%url%', upgradeToNextRange || '');

    const onSearchInstallations = (): void => {
        dispatch(actions.installations.setSearchInput(input));
    };

    return (
        <>
            <ThemeProvider theme={invertedTheme}>
                <Modal>
                    <ModalHeader closeHandler={close}>
                        {i18n('subscription_add_installations_overlay_title')}
                    </ModalHeader>
                    <ModalBody>
                        {isSaving && (
                            <div className="text-center">
                                <p>
                                    <SmallLoader />
                                    &nbsp;
                                    {i18n('saving')}
                                </p>
                            </div>
                        )}
                        {isLoading && (
                            <div className="text-center">
                                <p>
                                    <SmallLoader />
                                    &nbsp;
                                    {i18n('loading_installations')}
                                </p>
                            </div>
                        )}
                        {!isLoading && installations.length === 0 && (
                            <Styles.Message>{i18n('no_installations')}</Styles.Message>
                        )}
                        {!isLoading && installations.length > 0 && (
                            <div>
                                <div className="row">
                                    <p
                                        className="col-sm-12 col-md-6 col-lg-6"
                                        dangerouslySetInnerHTML={{
                                            __html: totalNrOfInstallationsLabel,
                                        }}
                                    />
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <Styles.SearchBar htmlFor="search" className="pull-right">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="search_field"
                                                id="search"
                                                placeholder={i18n('search')}
                                                onChange={(e) => setInput(e.currentTarget.value)}
                                                value={input}
                                            />
                                            <button
                                                type="button"
                                                onClick={onSearchInstallations}
                                                disabled={
                                                    !installations.length || isLoading || isSaving
                                                }
                                            >
                                                <Icons.Glyph.Search size={19} />
                                            </button>
                                        </Styles.SearchBar>
                                    </div>
                                </div>
                                <InstallationsTable
                                    installations={filteredInstallations}
                                    selected={selected}
                                />
                            </div>
                        )}
                        {showAlert && (
                            <Styles.Alert>
                                <div
                                    id="upgrade-subscription"
                                    className="alert alert-danger"
                                    dangerouslySetInnerHTML={{ __html: alertMessage }}
                                />
                            </Styles.Alert>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            className="subscriptions-btn subscriptions-btn--primary"
                            data-dismiss="modal"
                            onClick={() => dispatch(actions.installations.saveInstallations())}
                            disabled={
                                isLoading ||
                                showAlert ||
                                isSaving ||
                                !installations.length ||
                                selected.length === 0
                            }
                        >
                            {footerLabelBtn}
                        </button>
                    </ModalFooter>
                </Modal>
            </ThemeProvider>

            <ModalBackDrop />
        </>
    );
};

const Wrapper = (): JSX.Element | null => {
    const overlayOpen = useAppSelector((state) => state.appState.isOverlayOpen);

    const dispatch = useDispatch();
    const open = useCallback(() => dispatch(actions.appState.openOverlay()), [dispatch]);
    const close = useCallback(() => dispatch(actions.appState.closeOverlay()), [dispatch]);

    useEffect(() => {
        const addInstallationsBtn = document.getElementById('add_installations_button');
        if (!addInstallationsBtn) {
            Logger.error(
                '[Subscriptions] Not possible to order installations, DOM-element is missing',
            );
        }
        addInstallationsBtn?.addEventListener('click', open);
        // Remove eventListeners on unmount
        return () => addInstallationsBtn?.addEventListener('click', open);
    }, [open]);

    return overlayOpen ? <App close={close} /> : null;
};

export default Wrapper;
